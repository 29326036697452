<template>
  <div id="counter-label-print">
    <div class="noprint shipments-page">
      <div class="flex items-center gap-4 mb-6">
        <div class="flex items-center gap-2 page-title p-4">
          <div :style="$route.query.tab === tab ? 'background: #1C5BFE; color: white' : ''" class="py-2 px-4 cursor-pointer d-theme-dark-bg rounded-md hover:shadow-drop"
            v-for="tab in tabs" :key="tab.index" @click="changeTabFilter(tab)">
            <div class="text-sm">{{$t(tab)}}</div>
          </div>
        </div>
        <div v-if="activeTab == 2 || activeTab == 3">
          <div class="flex items-center gap-2 page-title p-4">
            <vs-button class="py-2 px-4" color="primary" @click="printTaskList">{{$t('Print Returns')}}</vs-button>
            <merchants-button class="py-2 px-4 cursor-pointer rounded-md" :selected="returnsSelected" @merchantFilter="merchantFilter = $event"></merchants-button>
            <vs-button class="py-2 px-4" color="primary" @click="confirmReturns">{{$t('Confirm')}}</vs-button>
          </div>
        </div>
      </div>
      <orders v-if="activeTab == 0"/>
      <returns v-else-if="activeTab === 1"/>
      <counter-returns v-else-if="activeTab === 2" @returnsSelected="returnsSelected = $event" :merchantFilter="merchantFilter"></counter-returns>
      <counter-returns v-else @returnsSelected="returnsSelected = $event" :merchantFilter="merchantFilter"/>
    </div>
    <print-task-list class="print-label" :taskList="taskSheet" :returns="returnsSelected" :merchantOrders="merchantOrders"></print-task-list>
  </div>
</template>

<script>
import Orders from './Orders.vue'
import Returns from './Returns.vue'
import PrintTaskList from './components/PrintTaskList.vue'
import CounterReturns from '../manager/components/CounterReturns.vue'
import MerchantsButton from '../manager/components/MerchantsButton.vue'
import { sendRequest } from '../../http/axios/requestHelper'
import i18nData from '../../i18n/i18nData'

export default {
  data () {
    return {
      merchantFilter: '',
      taskSheet: {
        merchant_agent: {}
      },
      returnsSelected: [],
      merchantOrders: [],
      tabs: ['Delivery Orders', 'Returns', 'Counter RTOs', 'Counter Returns'],
      activeTab: 0,
      tabsDic: {'0': 'Delivery Orders', '1': 'Returns', '2': 'Counter RTOs', '3': 'Counter Returns'},
      tabsNameDic: {'Delivery Orders': 0, 'Returns': 1, 'Counter RTOs': 2, 'Counter Returns': 3}
    }
  },
  watch: {
    '$route.query.tab' () {
      this.activeTab = this.tabsNameDic[this.$route.query.tab]
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: 1
        }
      }).catch(() => {})
    },
    activeTab () {
      this.merchantFilter = ''
      this.returnsSelected = []
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.$route.query.page ? this.$route.query.page : 1
        }
      }).catch(() => {})
    }
  },
  methods: {
    confirmReturns (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure you want to confirm these Counter Returns?'],
        accept: this.updateReturnsStatus
      })
    },
    updateReturnsStatus () {
      const trackings = []
      this.returnsSelected.forEach(item => {
        trackings.push({
          order: item.id,
          packages: item.packages.map(item => item.package.id),
          status: 'returned'
        })
      })
      sendRequest(false, false, this, 'api/v1/tracking/', 'post', trackings, true,
        () => {
          this.merchantFilter = {}
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Delivery Order'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
        }
      )
    },
    printTaskList () {
      window.print()
    },
    changeTabFilter (tab) {
      this.activeTab = this.tabsNameDic[tab]
      this.$router.push({
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.activeTab,
          filter: this.filters,
          type: this.typeFilter
        }
      }).catch(() => {})
    }
  },
  components: {
    Orders,
    Returns,
    PrintTaskList,
    CounterReturns,
    MerchantsButton
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  created () {
    this.activeTab =  this.$route.query.tab ? this.tabsNameDic[this.$route.query.tab] : '0'
  }
}
</script>

<style lang="scss">
.print-label {
  display: none;
}
@media print {
  .noprint, .vs-sidebar, .vs-sidebar-primary {
    display: none;
  }
  .print-label {
    display: initial;
  }
}
</style>